// Example usage:

// export enum FeatureFlagNamesEnum {
//   TEST_FEATURE_ENABLED = 'TEST_FEATURE_ENABLED',
// }

// export const featureFlags = {
//   TEST_FEATURE_ENABLED: process.env.TEST_FEATURE_ENABLED?.toLowerCase() === 'true',
// }

// If checking for a flag on a *page* level, you can do something like this:

// import { FeatureFlagNamesEnum, featureFlags } from "@/lib/feature-flags"
// ...
// const isTestFeatureEnabled = featureFlags[FeatureFlagNamesEnum.TEST_FEATURE_ENABLED];
// if (!isTestFeatureEnabled) {
//   return notFound();
// }

// For feature flags at the component level, you can use the Feature component
// import { FeatureFlagNamesEnum } from "@/lib/feature-flags"
// import Feature from '@/components/Feature/Feature';
// ...
// <Feature featureFlag={FeatureFlagNamesEnum.TEST_FEATURE_ENABLED} enabled>
//   <h2>Look Ma, this feature is enabled!</h2>
// </Feature>
// <Feature featureFlag={FeatureFlagNamesEnum.TEST_FEATURE_ENABLED} disabled>
//   <h2>Look Ma, no feature!</h2>
// </Feature>

export enum FeatureFlagNamesEnum {
  AMERICA_AT_250_EXPLORE_HUB_ENABLED = 'AMERICA_AT_250_EXPLORE_HUB_ENABLED',
};

export const featureFlags = {
  AMERICA_AT_250_EXPLORE_HUB_ENABLED: process.env.NEXT_PUBLIC_AMERICA_AT_250_EXPLORE_HUB_ENABLED?.toLowerCase() === 'true',
};
