import { ExploreTopicLabelEnum, ExploreTopicRouteEnum } from "@/lib/constants";
import { FeatureFlagNamesEnum, featureFlags } from "@/lib/feature-flags";
interface ConditionalTypes {
  isSVP: boolean;
}

export const contentNavLinks = (conditions: ConditionalTypes) => ([
  { label: 'My Station', href: '/', condition: conditions.isSVP },
  { label: 'Featured', href: '/', condition: !conditions.isSVP},
  { label: ExploreTopicLabelEnum.Drama, href: ExploreTopicRouteEnum.Drama },
  { label: ExploreTopicLabelEnum.DocumentariesAndIndieFilms, href: ExploreTopicRouteEnum.DocumentariesAndIndieFilms },
  { label: ExploreTopicLabelEnum.History, href: ExploreTopicRouteEnum.History },
  { label: ExploreTopicLabelEnum.NewsAndPublicAffairs, href: ExploreTopicRouteEnum.NewsAndPublicAffairs },
  { label: ExploreTopicLabelEnum.ScienceAndNature, href: ExploreTopicRouteEnum.ScienceAndNature },
  { label: ExploreTopicLabelEnum.Culture, href: ExploreTopicRouteEnum.Culture },
  { label: ExploreTopicLabelEnum.Food, href: ExploreTopicRouteEnum.Food },
  { label: ExploreTopicLabelEnum.HomeAndHowTo, href: ExploreTopicRouteEnum.HomeAndHowTo},
  { label: ExploreTopicLabelEnum.Passport, href: ExploreTopicRouteEnum.Passport, include_passport_icon: true },
])

const allGenreLinks = {
  genres: [
    { label: ExploreTopicLabelEnum.Arts, href: ExploreTopicRouteEnum.Arts},
    { label: ExploreTopicLabelEnum.Culture, href: ExploreTopicRouteEnum.Culture},
    { label: ExploreTopicLabelEnum.DocumentariesAndIndieFilms, href: ExploreTopicRouteEnum.DocumentariesAndIndieFilms },
    { label: ExploreTopicLabelEnum.Drama, href: ExploreTopicRouteEnum.Drama },
    { label: ExploreTopicLabelEnum.Food, href: ExploreTopicRouteEnum.Food },
    { label: ExploreTopicLabelEnum.History, href: ExploreTopicRouteEnum.History },
    { label: ExploreTopicLabelEnum.HomeAndHowTo, href: ExploreTopicRouteEnum.HomeAndHowTo},
    { label: ExploreTopicLabelEnum.NewsAndPublicAffairs, href: ExploreTopicRouteEnum.NewsAndPublicAffairs},
    { label: ExploreTopicLabelEnum.ScienceAndNature, href: ExploreTopicRouteEnum.ScienceAndNature },
  ],
  featured: [
    { label: ExploreTopicLabelEnum.Passport, href: ExploreTopicRouteEnum.Passport, include_passport_icon: true},
    { label: ExploreTopicLabelEnum.HenryLouisGatesJr, href: ExploreTopicRouteEnum.HenryLouisGatesJr},
    { label: ExploreTopicLabelEnum.Civics, href: ExploreTopicRouteEnum.Civics},
  ]
}

const mobileGenreLinks = [
  { label: ExploreTopicLabelEnum.Passport, href: ExploreTopicRouteEnum.Passport, passport: true},
  { label: ExploreTopicLabelEnum.Arts, href: ExploreTopicRouteEnum.Arts},
  { label: ExploreTopicLabelEnum.Culture, href: ExploreTopicRouteEnum.Culture },
  { label: ExploreTopicLabelEnum.DocumentariesAndIndieFilms, href: ExploreTopicRouteEnum.DocumentariesAndIndieFilms },
  { label: ExploreTopicLabelEnum.Drama, href: ExploreTopicRouteEnum.Drama },
  { label: ExploreTopicLabelEnum.HenryLouisGatesJr, href: ExploreTopicRouteEnum.HenryLouisGatesJr},
  { label: ExploreTopicLabelEnum.Civics, href: ExploreTopicRouteEnum.Civics},
  { label: ExploreTopicLabelEnum.Food, href: ExploreTopicRouteEnum.Food },
  { label: ExploreTopicLabelEnum.History, href: ExploreTopicRouteEnum.History },
  { label: ExploreTopicLabelEnum.HomeAndHowTo, href: ExploreTopicRouteEnum.HomeAndHowTo},
  { label: ExploreTopicLabelEnum.NewsAndPublicAffairs, href: ExploreTopicRouteEnum.NewsAndPublicAffairs},
  { label: ExploreTopicLabelEnum.ScienceAndNature, href: ExploreTopicRouteEnum.ScienceAndNature },
]

const isAmericaAt250Enabled = featureFlags[FeatureFlagNamesEnum.AMERICA_AT_250_EXPLORE_HUB_ENABLED];

// if america at 250 is enabled, replace the civics links with a link to america at 250
if (isAmericaAt250Enabled) {
  const featuredCivicsIndex = allGenreLinks.featured.findIndex(link => link.label === ExploreTopicLabelEnum.Civics);
  if (featuredCivicsIndex !== -1) {
    allGenreLinks.featured[featuredCivicsIndex] = {
      label: ExploreTopicLabelEnum.AmericaAt250,
      href: ExploreTopicRouteEnum.AmericaAt250
    }
  }

  const mobileCivicsIndex = mobileGenreLinks.findIndex(link => link.label === ExploreTopicLabelEnum.Civics);
  if (mobileCivicsIndex !== -1) {
    mobileGenreLinks[mobileCivicsIndex] = {
      label: ExploreTopicLabelEnum.AmericaAt250,
      href: ExploreTopicRouteEnum.AmericaAt250
    }
  }
}


export { allGenreLinks, mobileGenreLinks };
